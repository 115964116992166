<template>
  <div class="mygame">
    <div class="gameIframeBox">
      <iframe :src="url" frameborder="0" width="100%" height="100%" class=""></iframe>
    </div>
    <div class="close-wrap"><van-icon name="cross" size=".8rem" color="#fff" @click="goBack()" /></div>
  </div>
</template>

<script>
export default {
  name: 'Play',
  data: function() {
    var token = localStorage.token ? localStorage.token : '726fc216-6132-4663-8229-d9f2804c79fe';
    var url = 'https://mania.slotgen.com/uploads/games/baccarat_sample1/index.html?token=' + token;
    return {
      url: url
    }
  },
  methods: {
    goBack() {
      this.$store.commit("setPageTransition", "back");
      this.$router.push({
        name: "Home"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mygame {
    position: fixed;
    width: 10rem;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: #fff;
    z-index: 20;
}

.gameIframeBox {
    position: relative;
    width: 100%;
    height: 100%;
}


.close-wrap {
    position: fixed;
    top: 0;
    padding-left: 9rem;
    z-index: 30;
}

</style>
